@font-face {
  font-family: georgia;
  src: url("georgia.97a70478.ttf");
}

@font-face {
  font-family: elephant;
  src: url("elephant.885c74a4.ttf");
}

@font-face {
  font-family: courier;
  src: url("courier.ed3b4a1e.ttf");
}

@font-face {
  font-family: arial;
  src: url("arial.3c1ebc97.ttf");
}

@font-face {
  font-family: helvetica;
  src: url("helvetica.ff598533.ttf");
}

#logArea {
  resize: none;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  overflow: auto;
}

#log-container {
  resize: none;
  width: 40%;
  height: 95%;
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

html, body {
  color: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#canvas {
  background-color: #008eaa;
  width: 740px;
  height: 100%;
}

#canvas-container {
  text-align: right;
  height: 100%;
}

#canvas-container.slide-in {
  animation-name: in-animation;
  animation-duration: 4s;
}

#canvas-container.slide-out {
  animation-name: out-animation;
  animation-duration: 4s;
}

@keyframes in-animation {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0;
  }
}

@keyframes out-animation {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 100%;
  }
}

/*# sourceMappingURL=index.d1119805.css.map */
